
// import axios from "axios";
// import Loading from "@/components/loading.vue";
import { defineComponent, ref, onMounted, watch, computed } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
import arraySort from "array-sort";
import { saveAs } from "file-saver";
import ApiService from "@/core/services/ApiService";
import Globals from "@/views/common/Globals.vue";
import { ElLoading } from "element-plus";
import { ElNotification } from "element-plus";
import alasql from "alasql";
interface IPagination {
  page: number;
  total: number;
  rowsPerPage: number;
}
interface ICustomer {
  id: string;
  name: string;
  group: string;
  email: string;
}
export default defineComponent({
  name: "OrganizationAPIs",
  mixins: [Globals],
  components: {},
  data() {
    const pagination = ref<IPagination>({
      page: 1,
      total: 0,
      rowsPerPage: 10,
    });
    return {
      initialPageSize: "25",
      symbolFlag: true,
      organizationData: [],
      orgDataTemp: [],
      searchQuery: "",
      manufactureData: [],
      page: 1,
      orgList: [],
      orgData: [] as any,
      flag: false,
      configData: [],
      status: [] as any,
      apiDataChild: [] as any,
      configuremanufacturerdata: [],
      render: false,
      initCust: [],
      search: "",
      searchFlag: true,
      manufactId: "",
      array: [] as any,
      apiLogDateRangeError: "",
      track: "",
      trackFlag: true,
      sortBy: "",
      sortDirection: "asc",
      cats: [],
      currentSort: "name",
      currentSortDir: "asc",
      temp: [],
      pagination,
      totalCount: 0,
      pages: 0,
      rowPerPage: 10,
      pageNumber: 1,
      pageSize: 10,
      sortFlag: true,
      orgType: "M",
      org: "",
      oneOrg: false,
    };
  },
  mounted() {
    // this.getconfiguremanufacturer();
    this.getOrganizations();
  },
  methods: {
    //Downloads
    downloads() {
      //  this.loading = true;
      var keys = Object.keys(this.organizationData[0]);
      let csv = "";
      let csvInside = "";
      let headerFlag = true;

      for (var line of this.organizationData) {
        csv += keys.join(",") + "\n";
        csv +=
          keys.map((key) => {
            if (key != "data") {
              return JSON.stringify(line[key]);
            } else {
              for (var lineInside of line["data"]) {
                var keys2 = Object.keys(lineInside);
                if (headerFlag) {
                  csvInside = csvInside + keys2.join(",") + "\n";
                  headerFlag = false;
                }
                csvInside += "\n";

                csvInside += keys2.map((key) => {
                  return JSON.stringify(lineInside[key]);
                });
              }
            }
          }) + "\n";
        csvInside;
      }
      var blob = new Blob([csvInside], { type: "text/csv;charset=utf-8;" });
      // this.loading = false;
      saveAs(blob, "APIs.csv");
    },
    //Get Organization
    getOrganization() {
      ApiService.get(`/organizations/${this.org}/apis?featureMode=organization_apis_status`)
        .then((data) => {
          this.totalCount = 1;
          this.pages = 1;
          this.oneOrg = true;
          const status = alasql("SELECT COLUMN DISTINCT latestStatus FROM ?", [
            data.data.data.api_list,
          ]);
          const orgData = this.orgList.find((item) => item.id === this.org);

          const lastUpdatedTime = alasql(
            "SELECT COLUMN DISTINCT lastStatusChangeDate FROM ? ORDER BY lastStatusChangeDate DESC",
            [data.data.data.api_list]
          );
          const averageAvailability = alasql(
            "SELECT AVG(availabilityPercentage) as availabilityPercentage FROM ?",
            [data.data.data.api_list]
          );

          if (status.length > 1) {
            status.splice(0, status.length, "partial");
          } else if (status[0] === undefined) {
            status.splice(0, status.length, "NA");
          }
          const dataList = data.data.data.api_list;
          var dataFormat = lastUpdatedTime[0];
          this.organizationData.length = 0;
          this.organizationData.push({
            name: orgData.name,
            latestStatus: status[0],
            organizationType: this.orgType,
            industry: orgData.industry,
            lastStatusChangeDate:
              lastUpdatedTime[0] != (undefined || null)
                ? dataFormat
                : "Not Available",
            availabilityPercentage:
              averageAvailability[0].availabilityPercentage != undefined
                ? averageAvailability[0].availabilityPercentage.toFixed(2) + "%"
                : "Not Available",
            data: dataList,
          });
        })
        .catch((error) => {
          console.log("Error IN manufacture APi", error);
        });
    },
    //seCurrentPage
    setCurrent(val) {
      if (this.page != val) {
        this.page = val;
        this.getOrganizations();
      }
    },
    //stPageChange
    pageChange() {
      this.getOrganizations();
    },
    //get Date Format
    // getDateInUtc(incomingDate) {
    //   incomingDate = incomingDate.replace("T", " ");
    //   incomingDate = new Date(incomingDate);
    //   incomingDate.setMinutes(
    //     incomingDate.getMinutes() - incomingDate.getTimezoneOffset()
    //   );
    //   incomingDate =
    //     incomingDate.toDateString() +
    //     " " +
    //     incomingDate.toLocaleTimeString() +
    //     " " +
    //     new Date(incomingDate)
    //       .toLocaleDateString("en-IN", {
    //         day: "2-digit",
    //         timeZoneName: "short",
    //       })
    //       .substring(4);
    //   return incomingDate;
    //   // const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;- get time from user browser
    //   // incomingDate=  incomingDate.toLocaleString()+" "+timezone ;
    //   // return incomingDate
    // },
    pageFunction(x) {
      this.render = false;
      let l = this.temp.length;
      this.configuremanufacturerdata = Object.create(this.temp);
      this.configuremanufacturerdata.splice(x, l - x);
      this.render = true;
    },
    sorting(s) {
      //if s == current sort, reverse
      if (this.sortFlag) {
        this.sortFlag = false;
        arraySort(this.organizationData, s, { reverse: true });
      } else {
        this.sortFlag = true;

        arraySort(this.organizationData, s, { reverse: false });
      }

      if (s === this.sortBy) {
        this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
      }
      this.sortBy = s;
    },
    //Search Method
    searchQueryMethod() {
      this.organizationData.splice(
        0,
        this.organizationData.length,
        ...this.initCust
      );
      if (this.searchQuery !== "") {
        let results = [];
        for (let j = 0; j < this.organizationData.length; j++) {
          if (this.searchingFunc(this.organizationData[j], this.searchQuery)) {
            results.push(this.organizationData[j]);
          }
        }
        this.organizationData.splice(
          0,
          this.organizationData.length,
          ...results
        );
        if (this.organizationData.length == 0 && this.searchFlag == true) {
          this.searchFlag = false;
          ElNotification({
            title: "Warning",
            message: "No Records Found!",
            type: "warning",
            duration: 2000,
            position: "top-right",
          });
        } else if (this.organizationData.length != 0) {
          this.searchFlag = true;
        }
      }
    },
    //Search Function
    searchingFunc(obj, value) {
      for (let key in obj) {
        if (
          !Number.isInteger(obj[key]) &&
          !(typeof obj[key] === "number") &&
          !(typeof obj[key] === "object")
        ) {
          if (obj[key].toLowerCase().indexOf(value.toLowerCase()) != -1) {
            return true;
          }
        }
      }
      return false;
    },

    //get Oraganization List calculate Status
    getOrganizations() {
      this.organizationData.length = 0;
      this.oneOrg = false;
      this.org = "";
      const loading = ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      if (this.orgType != "") {
        ApiService.get(`/organizations?organizationType=${this.orgType}`)
          .then((data) => {
            this.orgList = data.data.data.organization_list;
          })
          .catch((error) => {
            console.log("Error in all org", error);
          });
      }

      ApiService.get(
        `/organizations?organizationType=${this.orgType}&pageNumber=${this.page}&pageSize=${this.rowPerPage}`
      )
        .then((data) => {
          // this.orgList = data.data.data.organization_list;
          this.totalCount = data.data.data.total_count;
          this.pages = Math.ceil(this.totalCount / this.rowPerPage);
          loading.close();
          if (data.data.data.organization_list.length != 0) {
            return data.data.data.organization_list.map((item, index) =>
              ApiService.get(`/organizations/${item.id}/apis?featureMode=organization_apis_status`)
                .then((data) => {
                  loading.close();
                  const status = alasql(
                    "SELECT COLUMN DISTINCT latestStatus FROM ?",
                    [data.data.data.api_list]
                  );
                  const lastUpdatedTime = alasql(
                    "SELECT COLUMN DISTINCT lastStatusChangeDate FROM ? ORDER BY lastStatusChangeDate DESC",
                    [data.data.data.api_list]
                  );
                  const averageAvailability = alasql(
                    "SELECT AVG(availabilityPercentage) as availabilityPercentage FROM ?",
                    [data.data.data.api_list]
                  );

                  if (status.length > 1) {
                    status.splice(0, status.length, "partial");
                  } else if (status[0] === undefined) {
                    status.splice(0, status.length, "NA");
                  }
                  const dataList = data.data.data.api_list;
                  var dataFormat = lastUpdatedTime[0];
                  this.organizationData.push({
                    name: item.name,
                    latestStatus: status[0],
                    organizationType: item.organizationType,
                    industry: item.industry,
                    lastStatusChangeDate:
                      lastUpdatedTime[0] != undefined
                        ? dataFormat
                        : "Not Available",
                    availabilityPercentage:
                      averageAvailability[0].availabilityPercentage != undefined
                        ? averageAvailability[0].availabilityPercentage.toFixed(
                            2
                          ) + "%"
                        : "Not Available",
                    data: dataList,
                  });
                })
                .catch((error) => {
                  loading.close();

                  console.log("Error IN manufacture APi", error);
                })
            );
          }
        })
        .catch((error) => {
          loading.close();

          console.log("Error In side Organization", error);
        });
    },

    displayData(data) {
      this.temp = JSON.parse(JSON.stringify(data));
      this.configuremanufacturerdata = Object.create(this.temp);
      this.pageFunction(this.initialPageSize);
      this.initCust.splice(
        0,
        this.configuremanufacturerdata.length,
        ...this.configuremanufacturerdata
      );
    },
    getconfiguremanufacturer() {
      this.render = false;
      const loading = ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      ApiService.get(
        "/manufacturerStatus/Dashboard?organizationId=" +
          this.globalData.organizationId
      )
        .then((data) => {
          loading.close();
          this.displayData(data.data.data);
        })
        .catch((error) => {
          loading.close();
          console.log(error);
        });
    },
    displayData1(data) {
      this.apiDataChild.push(JSON.parse(JSON.stringify(data)));
      if (this.apiDataChild.length != 0) {
        this.render = true;
      }
    },

    getEvents(Id, name, index) {
      // console.log("id:",Id,index)
      let statusLength = this.status.length;
      let breakFlag = false;
      for (let i = 0; i < this.status.length; i++) {
        if (this.status[i] == index) {
          this.flag = true;
          for (let j = 0; j < this.apiDataChild.length; j++) {
            for (let k = 0; k < this.apiDataChild[j].length; k++) {
              let splitData = this.apiDataChild[j][k]["Organame"];
              if (name === splitData) {
                this.apiDataChild.splice(j, 1);
                breakFlag = true;
                break;
              }
            }
            if (breakFlag == true) break;
          }

          this.status.splice(i, 1);
          break;
        }
      }
      if (this.flag == false) {
        this.render = false;
        this.manufactId = Id;
        ApiService.get(
          "/manufacturerStatus?organizationId=" +
            this.globalData.organizationId +
            "&manufacturerId=" +
            this.manufactId
        )
          .then((data) => {
            // loading.close();
            let data1 = JSON.parse(JSON.stringify(data.data.data));
            this.apiDataChild.push(data1);

            // this.apiDataChild[Id]=data1
            this.status.push(index);

            // this.displayData1(data.data.data);
          })
          .catch((error) => {
            this.apiLogDateRangeError = JSON.stringify(
              error.response.data["errors"][0]["errorMessage"]
            );
          });
      }

      if (statusLength == this.status.length + 1) {
        this.flag = false;
      }
    },
  },

  computed: {
    sorted: function () {
      let a = [];
      a = this.organizationData;
      return a.sort((p1, p2) => {
        let modifier = 1;
        if (this.sortDirection === "desc") modifier = -1;
        if (p1[this.sortBy] == null || p2[this.sortBy] == null) {
          return 0 * modifier;
        }
        if (this.sortBy == "ui_quote_id") {
          if (parseInt(p1[this.sortBy]) < parseInt(p2[this.sortBy]))
            return -1 * modifier;
          if (parseInt(p1[this.sortBy]) > parseInt(p2[this.sortBy]))
            return 1 * modifier;
        }
        if (this.sortBy == "total_premium") {
          if (parseInt(p1[this.sortBy]) < parseInt(p2[this.sortBy]))
            return -1 * modifier;
          if (parseInt(p1[this.sortBy]) > parseInt(p2[this.sortBy]))
            return 1 * modifier;
        }
        if (p1[this.sortBy] < p2[this.sortBy]) return -1 * modifier;
        if (p1[this.sortBy] > p2[this.sortBy]) return 1 * modifier;
        return 0;
      });
    },
  },
  setup() {
    onMounted(() => {
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs("View Orgnization API", ["Manage"]);
    });
  },
});
